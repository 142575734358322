import { CallbotConfigDemo, ConfigForm, FileUpload } from '../Style';

import Actions from 'src/Components/Actions/Actions';
import { CONFIG_TYPES } from 'src/Tools/Constants';
import { CreateConfigProps } from '../CreateConfigContainer';
import Icon from 'src/Components/Icon/Icon';
import Message from 'src/Components/Message/Message';
import { formatBytes } from 'src/Tools/Files';
import iconZip from 'src/Assets/Images/icon-zip.png';
import { useTranslation } from 'react-i18next';
import UpdateName from '../../UpdateName/UpdateName';
import { MultiSelect } from 'primereact/multiselect';
import { useSelector } from 'react-redux';
import { getConsultationSpace, getCurrentBot, getLanguages } from '../../../Redux/Bot/selectors';
import Input from '../../../Components/Input/Input';
import { FloatLabel } from 'primereact/floatlabel';
import { useEffect } from 'react';

const CreateType = ({
  createType,
  type,
  configName,
  configFile,
  selectFile,
  actions,
  setConfigName,
  setLanguage,
  setConsultationSpace,
  consultationSpace,
  language,
}: CreateConfigProps) => {
  const { t } = useTranslation();
  const consultationSpaces = useSelector(getConsultationSpace);
  const currentBot = useSelector(getCurrentBot);
  const languagesList = useSelector(getLanguages);

  const renderReplaceMessage = () =>
    createType === 'replace' && <Message severity='warn' text={t('newConfig.modal.create_form.replace_config_warn')} />;

  const renderImportMessage = () =>
    type === CONFIG_TYPES.CUSTOMER && (
      <Message severity='info' text={t('newConfig.modal.create_form.import_config_info')} />
    );

  const renderFileInput = () =>
    createType &&
    ['import', 'replace'].includes(createType) && (
      <>
        <FileUpload onClick={selectFile}>
          {renderImportMessage()}
          <div className='label'>{t('newConfig.modal.create_form.file_label')}</div>
          <div className='content'>
            <div className='image'>
              <img src={iconZip} />
            </div>
            <div className='inner'>
              <div className='text'>
                <div className='name'>{configFile?.name || t('newConfig.modal.create_form.file_empty')}</div>
                <div className='size'>{formatBytes(configFile?.size)}</div>
              </div>
              <Icon icon='upload' className='upload-icon' color='white' />
            </div>
          </div>
          {renderReplaceMessage()}
        </FileUpload>
      </>
    );

  const handleLanguageChange = (e) => {
    setLanguage(e);
  };
  const handleConsultationSpaceChange = (e) => {
    setConsultationSpace(e);
  };

  useEffect(() => {
    if (type === CONFIG_TYPES.CALLBOT) {
      currentBot && setConfigName(currentBot?.name);
    }
  }, [currentBot]);

  const renderNameInput = () => {
    if (type === CONFIG_TYPES.CALLBOT) {
      return (
        <>
          <div className={'warning-demo-instance'}>{t('newConfig.modal.subtitleDemoCallbot')}</div>
          <Input value={currentBot?.name} label={t('general.name')} disabled={true} />
          <CallbotConfigDemo>
            <FloatLabel>
              <MultiSelect
                type={type}
                options={consultationSpaces?.map((space) => space.name)}
                onChange={(e) => handleConsultationSpaceChange(e.target.value)}
                selectionLimit={1}
                name={t('newConfig.modal.create_form.consultation_space')}
                value={consultationSpace}
              />
              <label>{t('edition.additionalInfo.field.consultationSpace.placeholder')}</label>
            </FloatLabel>
            <FloatLabel>
              <MultiSelect
                type={type}
                options={languagesList?.map((language) => language.id)}
                onChange={(e) => handleLanguageChange(e.target.value)}
                selectionLimit={1}
                name={t('newConfig.modal.create_form.language')}
                value={language}
                pt={{
                  label: { single: '1 language', multiple: '{{count}} languages' },
                }}
              />
              <label>{t('general.language')}</label>
            </FloatLabel>
          </CallbotConfigDemo>
        </>
      );
    }
    return (
      createType !== 'replace' && (
        <UpdateName
          actions={[]}
          className='config-name'
          label={t('newConfig.modal.create_form.input_label')}
          configName={configName}
          placeholder={t('newConfig.modal.create_form.name.placeholder')}
          setConfigName={setConfigName}
        />
      )
    );
  };
  return (
    <ConfigForm>
      {renderNameInput()}
      {renderFileInput()}
      <Actions actions={actions} />
    </ConfigForm>
  );
};

export default CreateType;
