import ControlledSwitch from 'src/Components/Switch/ControlledSwitch';
import { DisplayProps } from '../Display';
import { Divider } from 'src/Components/Divider/Style';
import { InnerContent } from 'src/Pages/Edition/Style';
import Switch from 'src/Components/Switch/Switch';
import { useTranslation } from 'react-i18next';

interface KnowledgeProps extends DisplayProps {}

const Knowledge = ({ control, setValue, watch }: KnowledgeProps) => {
  const { t } = useTranslation();

  return (
    <InnerContent className='knowledge'>
      <ControlledSwitch
        control={control}
        label={t('edition.advanced.field.top3.label')}
        subtitle={t('edition.advanced.field.top3.subtitle')}
        icon='star'
        name='content.topKnowledge.enable'
      />
      <Divider />
      <Switch
        label={t('edition.advanced.field.suggestionsLimit.title')}
        subtitle={t('edition.advanced.field.suggestionsLimit.subtitle')}
        checked={watch('content.suggestions.limit') === 3}
        icon='puzzle'
        onChange={() => setValue('content.suggestions.limit', watch('content.suggestions.limit') === 3 ? 0 : 3)}
      />
    </InnerContent>
  );
};

export default Knowledge;
